.sq-input {
    border: 1px solid rgb(195, 195, 195) !important;
    border-radius: 5px;
    padding: 8px 10px;
    margin-top: 25px !important;
    background: #fff;
    height: 38px !important;
    box-sizing: border-box;
}

.sq-input--focus {
    border: 2px solid #3f51b5;
    padding: 7px 9px;
}

.sq-input--error {
    border: 1px solid #f44336;
    padding: 8px 10px;
}

.sq-input--error.sq-input--focus  {
    border: 2px solid #f44336;
    padding: 7px 9px;
}

.sq-input-new-loading,
.sq-input-new {
    width: 100% !important;
    height: 52px !important;
    padding: 14px 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid rgb(195, 195, 195);
    box-sizing: border-box;
    z-index: 2;
}
.sq-input-new-loading {
    background: rgba(158, 158, 158, 0.5);
}
.sq-input-new {
    background: #fff;
}
.sq-input-new--focus {
    border-width: 2px;
    border-color: #3f51b5;
}
.sq-input-new--error {
    border-width: 2px;
    border-color: #FF0000;
}
.sq-input-new--error.sq-input--focus  {
    border-width: 2px;
    border-color: #FF0000;
}

.MuiPickersBasePicker-pickerViewLandscape {
    height: 355px;
    justify-content: flex-start !important;
}

#dctr-form,
#dctr-form-intake,
#dctr-form-application {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}